import { useRef } from 'react';
import { useSafeState } from 'ahooks';
import ActionSheet from '@/components/ActionSheet';
import { txMapKey } from '@/constants';
import { contactData, location } from '../../../common/contact/data';
import styles from './index.module.less';

function ContactMD() {
  const mapList = [
    {
      label: '高德地图',
      value: 'gd',
    },
    {
      label: '腾讯地图',
      value: 'tx',
    },
  ];
  const [showActionSheet, setShowActionSheet] = useSafeState(false);
  const target = useRef({
    name: '',
    address: '',
    lat: 0,
    lng: 0,
  });

  const handleMap = (index) => {
    target.current = {
      name: location[index].name,
      address: location[index].address,
      lat: location[index].latlng.lat,
      lng: location[index].latlng.lng,
    };
    setShowActionSheet(true);
  };

  const handleActionSheet = (value) => {
    const { name, address, lat, lng } = target.current;

    const txMap = {
      mobileUrl: 'qqmap://map/marker',
      h5Url: 'https://apis.map.qq.com/uri/v1/marker',
      query: `?marker=coord:${lat},${lng};title:${name};addr:${address}&referer=${txMapKey}`,
    };

    switch (value) {
      case 'gd':
        window.open(
          `https://uri.amap.com/marker?position=${lng},${lat}&name=${name}&src=zjjjszxh&coordinate=gaode&callnative=1`,
        );
        break;
      case 'tx':
        // 通过延时器判断是否安装了app
        window.location.href = `${txMap.mobileUrl}${txMap.query}`;
        setTimeout(() => {
          window.open(`${txMap.h5Url}${txMap.query}`);
        });
        break;
      default:
    }
    setShowActionSheet(false);
  };

  const handleCancel = () => {
    setShowActionSheet(false);
  };

  const renderAddress = () => (
    <div className={styles.address}>
      {location.map((item, index) => (
        <div key={index} className={styles.address__item}>
          <div className={styles.address__line}>
            <img
              src={require('@/assets/images/contact/location.png').default}
              alt=""
              className={styles.address__icon}
            />
            <div className={styles.address__detail}>
              <div>{item.name}</div>
              <div>{item.address}</div>
            </div>
          </div>
          <img
            src={item.imgList}
            alt=""
            className={styles.address__map}
            onClick={() => handleMap(index)}
          />
        </div>
      ))}
    </div>
  );

  const renderInfo = () => (
    <div className={styles.info}>
      {contactData.map((item) => (
        <div key={item.label} className={styles.info__line}>
          <span>{item.label}：</span>
          <span>{item.value}</span>
        </div>
      ))}
    </div>
  );

  const renderActionSheet = () => (
    <ActionSheet
      isVisible={showActionSheet}
      setVisible={setShowActionSheet}
      onCancel={handleCancel}
    >
      {mapList.map((item, index) => (
        <div key={index} onClick={() => handleActionSheet(item.value)} className={styles.sheet}>
          {item.label}
        </div>
      ))}
    </ActionSheet>
  );

  return (
    <section className={styles.contact}>
      {renderAddress()}
      {renderInfo()}
      {renderActionSheet()}
    </section>
  );
}

export default ContactMD;
